export default {
  firebaseConfig: {
    apiKey: 'AIzaSyB_COE6gBYWtDwbBm1_HNacSMayec3FabA',
    authDomain: 'programmable-recipes-prod.firebaseapp.com',
    databaseURL: 'https://programmable-recipes-prod.firebaseio.com',
    projectId: 'programmable-recipes-prod',
    storageBucket: 'programmable-recipes-prod.appspot.com',
    messagingSenderId: '505643653132',
    appId: '1:505643653132:web:18fb76a4fed9982590f629'
  }
}
